'use client'

import { useState } from 'react'

import { ClosetProvider } from '@marketplace-web/domain/vas'
import { ContentLoader, ErrorBoundary } from '@marketplace-web/shared/ui-helpers'
import { SellerPromotion } from '@marketplace-web/domain/welcome-image'
import { BannersProvider } from '@marketplace-web/domain/banners'
import useHomeTransactionalExposeEvents from 'hooks/useHomeTransactionalExposeEvents'
import { HomepageTabModel } from 'types/models/homepage-blocks'

import useFetchHomepageBlocks from './hooks/useFetchHomepageBlocks'
import ErrorModal from './common/ErrorModal'
import HomeTabs from './HomeTabs'
import HomeBlocks from './HomeBlocks'
import HomeBanners from './HomeBanners'
import useTabs from './hooks/useTabs'
import HomeProvider from './HomeProvider'
import useHomeObservability from './hooks/useHomeObservability'
import BlocksFetcher from './BlocksFetcher'

type Props = {
  visitsCount: number
}

const Home = ({ visitsCount }: Props) => {
  const [showErrorModal, setShowErrorModal] = useState(false)

  const { currentTab } = useTabs()
  const { trackBoundaryError, trackEmptyHome } = useHomeObservability()
  const { blocks, areBlocksLoading, fetchNewTab, fetchMoreBlocks } = useFetchHomepageBlocks({
    onEmpty: error => {
      setShowErrorModal(true)
      trackEmptyHome(error)
    },
  })

  const hasNextPage = !!blocks?.nextPageToken
  const showLoader = (hasNextPage && !blocks?.showLoadMoreButton) || areBlocksLoading

  useHomeTransactionalExposeEvents()

  return (
    <BannersProvider>
      <ErrorBoundary FallbackComponent={ErrorModal} preventLog onError={trackBoundaryError}>
        <HomeTabs onTabClick={fetchNewTab} />
        {currentTab.isSellerPromotionEnabled && <SellerPromotion visitsCount={visitsCount} />}
        <div className="container">
          <div className="homepage">
            <HomeBanners />
            {blocks && <HomeBlocks blocks={blocks} />}
            {hasNextPage && !areBlocksLoading && (
              <BlocksFetcher
                fetchMoreBlocks={fetchMoreBlocks}
                showLoadMoreButton={blocks.showLoadMoreButton}
              />
            )}
            {showLoader && (
              <ContentLoader
                size={ContentLoader.Size.Large}
                styling={ContentLoader.Styling.Tight}
                testId="homepage-loader"
              />
            )}
          </div>
        </div>
        {showErrorModal && <ErrorModal enableClose onCloseClick={() => setShowErrorModal(false)} />}
      </ErrorBoundary>
    </BannersProvider>
  )
}

type HomeWithProviderProps = Props & {
  homepageSessionId: string
  tabs: Array<HomepageTabModel>
  areChipsEnabled: boolean
}

const HomeWithProvider = (props: HomeWithProviderProps) => (
  <HomeProvider {...props}>
    <ClosetProvider>
      <Home {...props} />
    </ClosetProvider>
  </HomeProvider>
)

export default HomeWithProvider
