'use client'

import { InfoBanner } from '@vinted/web-ui'

import { PortalMergeDraftItemsReminderModel } from 'types/models/portal-merge-draft-items-reminder-banner'

type Props = {
  banner: PortalMergeDraftItemsReminderModel
}

const PortalDraftItemReminderBanner = ({ banner }: Props) => {
  return (
    <div className="portal-draft-item-reminder-banner">
      <InfoBanner
        type={InfoBanner.Type.Info}
        title={banner.title}
        body={banner.subtitle}
        actions={[{ text: banner.actionTitle, url: banner.actionUrl }]}
      />
    </div>
  )
}

export default PortalDraftItemReminderBanner
