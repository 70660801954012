'use client'

import { useState, useEffect } from 'react'

import { getLocalStorageItem, setLocalStorageItem } from '@marketplace-web/shared/browser'

import StickyBanner from './StickyBanner'

type Props = {
  suffix?: JSX.Element
  prefix?: JSX.Element
}

const DismissibleStickyBanner = ({ prefix, suffix }: Props) => {
  const IS_SHIPPING_FEES_APPLIED_INFO_BANNER_DISMISSED =
    'is_shipping_fees_applied_info_banner_dismissed'
  const [isDismissed, setIsDismissed] = useState(true)

  useEffect(() => {
    setIsDismissed(getLocalStorageItem(IS_SHIPPING_FEES_APPLIED_INFO_BANNER_DISMISSED) === 'true')
  }, [IS_SHIPPING_FEES_APPLIED_INFO_BANNER_DISMISSED])

  const handleBannerDismiss = () => {
    setIsDismissed(true)
    setLocalStorageItem(IS_SHIPPING_FEES_APPLIED_INFO_BANNER_DISMISSED, 'true')
  }

  if (isDismissed) {
    return null
  }

  return (
    <>
      {prefix}
      <span data-testid="dismissible-sticky-banner">
        <StickyBanner onClose={handleBannerDismiss} />
      </span>
      {suffix}
    </>
  )
}

export default DismissibleStickyBanner
